
import QRCode from 'qrcode.react';
import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import CountdownClock from './CountdownClock';
import DownloadCard from "./DownloadCard";
import supporticon from "./images/supporticon.png"
import LaunchAnnouncement from './LaunchAnnouncement';
import { FaRocket } from 'react-icons/fa';  // Import a rocket icon from 'react-icons' library (or any other library of your choice)
import SponsoredBanner from "./SponsoredBanner";
// import ThaliBuilder from "./ThaliBuilder.jsx";


import { FaMinus, FaPlus, FaPizzaSlice, FaIceCream, FaHamburger, FaTrash, FaChevronDown, FaShoppingBasket, FaArrowUp  } from 'react-icons/fa';

import { FaUtensils, FaBreadSlice } from 'react-icons/fa';
import { GiIndianPalace,  } from 'react-icons/gi';



import { FaTimes } from 'react-icons/fa';
// Import images
import logo from './images/logom.png'; // Adjust the path if needed
import hooshoplogo from './images/hooshoplogo.JPG'; // Adjust the path if needed

// maincourse
// Importing images for main course items
import dalFryImage from './images/dalFry.jpg';
import kadhiPakodaImage from './images/kadhiPakoda.jpg';
import besanGattaImage from './images/besanGatta.jpg';
import rajmaImage from './images/rajma.jpg';
// import shahiPaneerImage from './images/shahiPaneer.jpg';
import alooMatarImage from './images/alooMatar.jpg';
// import vegKoftaImage from './images/vegKofta.jpg';
import mixVegImage from './images/mixVeg.jpg';
// import paneerButterMasalaImage from './images/paneerButterMasala.jpg';

// Importing images for bread items
import tawaRotiImage from './images/tawaRoti.jpg';
import lacchaParathaImage from './images/lacchaParatha.jpg';
import alooPyazParathaImage from './images/alooPyazParatha.jpg';
import plainNaanImage from './images/plainNaan.jpg';
import buttertawaroti from './images/buttertwaroti.jpg';

import sfedCholeImage from './images/sfed_chole.jpg';
import benganBhartaImage from './images/bengan_bharta.jpg';
import makhanDalFryImage from './images/makhan_dal_fry.jpg';
import missiRotiImage from './images/missi_roti.jpg';
import missiPyazRotiImage from './images/missi_pyaz_roti.jpg';
// import lenRotiImage from './images/len_roti.jpg';
import pyazRotiImage from './images/pyaz_roti.jpg';


// Importing image for Special Thali
// import specialThaliImage from './images/specialThali.jpg';
// // Import images
// import classicThaliImage from './images/proteinRichThaliImage.jpg';
// import rajasthaniThaliImage from './images/vegetarianSpecialThaliImage.jpg';
import royalThaliImage from './images/simplethalipng.png';
// import vegetarianSpecialThaliImage from './images/veg.jpg';
// import proteinRichThaliImage from './images/proteinRichThaliImage.jpg';
import fullThaliImage from './images/fullthali.jpeg';


// brownpizza
import whiteSaucePastaImage from './images/whiteSaucePastaImage.jpg';
import redSaucePastaImage from './images/redSaucePastaImage.jpg';
import mixedSaucePastaImage from './images/mixedSaucePastaImage.jpg';
import margarettaCheeseImage from './images/margretaCheeseImage.jpg';
import chilliPaneerPizzaImage from './images/chilliPaneerPizzaImage.jpg';
import veggieLoverPizzaImage from './images/veggieLoverPizzaImage.jpg';
import tandooriLoverPizzaImage from './images/tandooriLover.jpg';
import overloadedPizzaImage from './images/overloadedPizza.jpg';
import familyMealImage from './images/familyMealPizza.jpeg';
import onefamilyMealImage from './images/oneMediumPizzaPasta.jpeg';
import garlicBreadStickImage from './images/garlicBreadStickImage.jpg';
import garlicBreadImage from './images/garlicstuffBreadImage.jpg';

import masalaGrillBurgerImage from './images/masalaGrillBurgerImage.jpg';
import maxicanBurgerImage from './images/maxicanBurgerImage.jpg';
import paneerPizzaBurgerImage from './images/paneerPizzaBurgerImage.jpg';
import vegSupremeBurgerImage from './images/vegSupremeBurgerImage.jpg';
import maharajaBurgerImage from './images/maharajaBurgerImage.jpg';
import pizzaBrownSplBurgerImage from './images/pizzaBrownSplBurgerImage.jpg';
import coldSandwichImage from './images/coldSandwichImage.jpg';
import sweetcornSandwichImage from './images/sweetcornSandwichImage.jpg';
import paneerSandwichImage from './images/paneerSandwichImage.jpg';
import vegGrillSandwichImage from './images/vegGrillSandwichImage.jpg';
import tandooriSandwichImage from './images/tandooriSandwichImage.jpg';
import superSaladSandwichImage from './images/supersaladsandwich.jpg';


// shivganga
import barfiHalfKg from './images/barfi_half_kg.jpg';
// import barfiFullKg from './images/barfi_half_kg.jpg';
import gulabJamunHalfKg from './images/gulab_jamun_half_kg.jpg';
// import gulabJamunFullKg from './images/gulabJamun.jpg';
import besanLadduHalfKg from './images/besan_laddu_half_kg.jpg';
// import besanLadduFullKg from './images/besan_laddu_half_kg.jpg';
import boondiLadduHalfKg from './images/boondi_laddu_half_kg.jpg';
// import boondiLadduFullKg from './images/boondi_laddu_half_kg.jpg';
// import matarMathriHalfKg from './images/matar_mathri_half_kg.jpg';
// import matarMathriFullKg from './images/matar_mathri_full_kg.jpg';
import kalakandFullKg from './images/kalakand.png';


// Component for displaying menu items
const MenuItem = ({ item, onAdd, onRemove, isHindi, message }) => (
  <div className="menu-item">
    <img src={item.image} alt={item.name} />
    <div className="item-details">
      <h4>{isHindi ? item.nameHi : item.name}</h4>
      <p>{isHindi ? item.descriptionHi : item.description}</p>
      <p>{isHindi ? `कीमत: ₹${item.price}` : `Price: ₹${item.price}`}</p>
      

       {/* Display the shop name as a clickable link */}
            <p dangerouslySetInnerHTML={{ __html: isHindi ? `दुकान का नाम: ${item.shop}` : `Shop: ${item.shop}` }}></p>

      <div className="item-actions">
        {item.quantity === 0 ? (
          <button className="checkoutbutton" onClick={() => onAdd(item)}>Add to Cart</button>
        ) : (
          <>
            <button onClick={() => onRemove(item)}><FaMinus /></button>
            <span>{item.quantity}</span>
            <button onClick={() => onAdd(item)}><FaPlus /></button>
          </>
        )}
      </div>

      {message && <p className="cart-message">{message}</p>} {/* Display message */}
    </div>
  </div>
);



// Component for displaying the basket items
// const Basket = ({ basketItems, onAdd, onRemove, isHindi, subtotal, tax, total }) => (
//   <div className="basket">
//     <h2>{isHindi ? 'खरीदी गई वस्तुएँ' : 'Basket'}</h2>
//     {basketItems.length === 0 ? (
//       <p>{isHindi ? 'कोई आइटम नहीं है' : 'No items in the basket'}</p>
//     ) : (
//       <div>
//         {basketItems.map(item => (
//           <div key={item.id} className="basket-item">
//             <img src={item.image} alt={item.name} />
//             <div className="item-details">
//               <h4>{isHindi ? item.nameHi : item.name}</h4>
//               <p>{isHindi ? item.descriptionHi : item.description}</p>
//               <div className="item-actions">
//                 <button onClick={() => onRemove(item)}><FaMinus /></button>
//                 <span>{item.quantity}</span>
//                 <button onClick={() => onAdd(item)}><FaPlus /></button>
//                 <button onClick={() => onRemove(item, true)}><FaTrash /></button>
//               </div>
//             </div>
//           </div>
//         ))}
//         <div className="basket-summary">
//           <p>{isHindi ? `उप-योग: ₹${subtotal}` : `Subtotal: ₹${subtotal}`}</p>
//           <p>{isHindi ? `कर (${TAX_RATE * 100}%): ₹${tax}` : `Tax (${TAX_RATE * 100}%): ₹${tax}`}</p>
//           <h3>{isHindi ? `कुल: ₹${total}` : `Total: ₹${total}`}</h3>
//         </div>
//       </div>
//     )}
//   </div>
// );

const shyamJidhaba = `<a href="https://maps.app.goo.gl/Bq6TwRwB8gC2rYTy8" target="_blank">Shyam Ji Dhaba</a>`;
const PizzaBrown = '<a href="https://maps.app.goo.gl/z5ARVSSazPWnjk127" target="_blank">Pizza Brown</a>';
const shivGanga = '<a href="https://www.google.com/maps/@28.910317,76.5899898,3a,75y,145.38h,96.53t/data=!3m7!1e1!3m5!1sIw-FleMLXdGEAOGhdaZh8A!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fcb_client%3Dmaps_sv.tactile%26w%3D900%26h%3D600%26pitch%3D-6.532923512631925%26panoid%3DIw-FleMLXdGEAOGhdaZh8A%26yaw%3D145.38437947170104!7i13312!8i6656?coh=205410&entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" target="_blank">Shiv Ganga Misthan Bhandar</a>';
const App = () => {
  const [menuItems, setMenuItems] = useState([
    { id: 19, name: 'Special Thali', nameHi: 'विशेष थाली', description: '4 Roti, 2 Sabzi, Salad, one piece Imarti/ Gulabjamun', descriptionHi: '4 रोटी, 2 सब्जी, सलाद, एक इमरती/गुलाबजामुन', Shopname: 'Shyam_ji_dhaba', category:  'thali', price: 70, quantity: 0, image: royalThaliImage, shop: shyamJidhaba },
     // Full Thali
  {
    id: 18, name: 'Full Thali', nameHi: 'पूर्ण थाली', description: '6 Roti, 3 Sabzi, 2 Mithai pieces, Salad', descriptionHi: '6 रोटी, 3 सब्जी, 2 मिठाई के टुकड़े, सलाद', Shopname: 'Shyam_ji_dhaba', category: 'special_thali', price: 120, quantity: 0, image: fullThaliImage, shop: shyamJidhaba },
    { id: 1, name: 'Dal Fry', nameHi: 'दाल फ्राई', description: 'Flavorful dal fry', descriptionHi: 'स्वादिष्ट दाल फ्राई', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 60, quantity: 0, image: dalFryImage, shop: shyamJidhaba },
  
  { id: 2, name: 'Kadhi Pakoda', nameHi: 'कढ़ी पकोड़ा', description: 'Tangy kadhi with pakodas', descriptionHi: 'खट्टी कढ़ी पकोड़ों के साथ', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 60, quantity: 0, image: kadhiPakodaImage, shop: shyamJidhaba },
  
  { id: 3, name: 'Besan Gatta', nameHi: 'बेसन गट्टा', description: 'Traditional Rajasthani dish', descriptionHi: 'पारंपरिक राजस्थानी व्यंजन', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 60, quantity: 0, image: besanGattaImage, shop: shyamJidhaba },
  
  { id: 4, name: 'Rajma', nameHi: 'राजमा', description: 'Delicious kidney beans curry', descriptionHi: 'स्वादिष्ट राजमा करी', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 60, quantity: 0, image: rajmaImage, shop: shyamJidhaba },
  
  { id: 5, name: 'Sfed Chole', nameHi: 'सफेद छोले', description: 'Delicious sfed chole', descriptionHi: 'स्वादिष्ट सफेद छोले', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 60, quantity: 0, image: sfedCholeImage, shop: shyamJidhaba },
  
  { id: 6, name: 'Bengan Bharta', nameHi: 'बैंगन भरता', description: 'Smoky bengan bharta', descriptionHi: 'धुँएदार बैंगन भरता', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 80, quantity: 0, image: benganBhartaImage, shop: shyamJidhaba },
  
  { id: 7, name: 'Makhan Dal Fry', nameHi: 'मक्खन दाल फ्राई', description: 'Creamy dal fry with butter', descriptionHi: 'मक्खन के साथ मलाईदार दाल फ्राई', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 100, quantity: 0, image: makhanDalFryImage, shop: shyamJidhaba },
  
  { id: 8, name: 'Aloo Matar', nameHi: 'आलू मटर', description: 'Classic potato and peas curry', descriptionHi: 'क्लासिक आलू और मटर करी', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 60, quantity: 0, image: alooMatarImage, shop: shyamJidhaba },
  
  { id: 9, name: 'Mix Veg', nameHi: 'मिक्स वेज', description: 'Mixed vegetable curry', descriptionHi: 'मिक्स सब्जी करी', Shopname: 'Shyam_ji_dhaba', category:  'maincourse', price: 80, quantity: 0, image: mixVegImage, shop: shyamJidhaba },
  
  { id: 10, name: 'Tawa Butter Roti', nameHi: 'तवा बटर रोटी', description: 'Cooked on a hot tawa', descriptionHi: 'गरम तवे पर पकाई गई', Shopname: 'Shyam_ji_dhaba', category:  'bread', price: 10, quantity: 0, image: buttertawaroti, shop: shyamJidhaba },
  
  { id: 11, name: 'Tawa Roti', nameHi: 'तवा रोटी', description: 'Cooked on a hot tawa', descriptionHi: 'गरम तवे पर पकाई गई', Shopname: 'Shyam_ji_dhaba', category:  'bread', price: 7, quantity: 0, image: tawaRotiImage, shop: shyamJidhaba },
  
  { id: 12, name: 'Laccha Paratha', nameHi: 'लच्छा पराठा', description: 'Layered Indian bread', descriptionHi: 'परतदार भारतीय रोटी', Shopname: 'Shyam_ji_dhaba', category:  'bread', price: 30, quantity: 0, image: lacchaParathaImage, shop: shyamJidhaba },
  
  { id: 13, name: 'Aloo Pyaz Paratha', nameHi: 'आलू प्याज पराठा', description: 'Stuffed with potato and onion', descriptionHi: 'आलू और प्याज से भरा हुआ', Shopname: 'Shyam_ji_dhaba', category:  'bread', price: 30, quantity: 0, image: alooPyazParathaImage, shop: shyamJidhaba },
  
  { id: 14, name: 'Plain Naan', nameHi: 'सादा नान', description: 'Soft and fluffy naan', descriptionHi: 'मुलायम और फूला हुआ नान', Shopname: 'Shyam_ji_dhaba', category:  'bread', price: 20, quantity: 0, image: plainNaanImage, shop: shyamJidhaba },
  
  { id: 15, name: 'Missi Roti', nameHi: 'मिस्सी रोटी', description: 'Crispy missi roti', descriptionHi: 'कुरकुरी मिस्सी रोटी', Shopname: 'Shyam_ji_dhaba', category:  'breads', price: 40, quantity: 0, image: missiRotiImage, shop: shyamJidhaba },
  
  { id: 16, name: 'Missi Pyaz Roti', nameHi: 'मिस्सी प्याज रोटी', description: 'Missi roti with onions', descriptionHi: 'प्याज के साथ मिस्सी रोटी', Shopname: 'Shyam_ji_dhaba', category:  'breads', price: 50, quantity: 0, image: missiPyazRotiImage, shop: shyamJidhaba },
  
  { id: 17, name: 'Pyaz Roti', nameHi: 'प्याज रोटी', description: 'Crispy pyaz roti', descriptionHi: 'कुरकुरी प्याज रोटी', Shopname: 'Shyam_ji_dhaba', category:  'breads', price: 45, quantity: 0, image: pyazRotiImage, shop: shyamJidhaba },

{ id: 20, name: 'White Sauce Pasta', nameHi: 'व्हाइट सॉस पास्ता', description: 'Creamy and rich white sauce pasta', descriptionHi: 'क्रीमी और स्वादिष्ट व्हाइट सॉस पास्ता', Shopname: 'Pizza_Brown', category:  'maincourse',  price: 89, quantity: 0, image: whiteSaucePastaImage, shop: PizzaBrown },
{ id: 21, name: 'Red Sauce Pasta', nameHi: 'रेड सॉस पास्ता', description: 'Tangy and spicy red sauce pasta', descriptionHi: 'चटपटा और मसालेदार रेड सॉस पास्ता', Shopname: 'Pizza_Brown', category:  'maincourse', price: 89, quantity: 0, image: redSaucePastaImage, shop: PizzaBrown },
{ id: 22, name: 'Mixed Sauce Pasta', nameHi: 'मिक्स सॉस पास्ता', description: 'A perfect blend of white and red sauces', descriptionHi: 'व्हाइट और रेड सॉस का स्वादिष्ट मिश्रण', Shopname: 'Pizza_Brown', category:  'maincourse', price: 110, quantity: 0, image: mixedSaucePastaImage, shop: PizzaBrown },// Margreta Cheese

// Premium Pizza List
{ id: 1001, name: 'Margaretta Cheese (Regular)', description: 'Cheese and more cheese!', price: 99, size: 'Regular', category: 'premiumPizza', quantity: 0, image: margarettaCheeseImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1002, name: 'Margaretta Cheese (Medium)', description: 'Cheese and more cheese!', price: 189, size: 'Medium', category: 'premiumPizza', quantity: 0, image: margarettaCheeseImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1003, name: 'Margaretta Cheese (Large)', description: 'Cheese and more cheese!', price: 329, size: 'Large', category: 'premiumPizza', quantity: 0, image: margarettaCheeseImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },

{ id: 1004, name: 'Chilli Paneer Pizza (Regular)', description: 'Paneer, Onion, Tomato, Red Paprika', price: 189, size: 'Regular', category: 'premiumPizza', quantity: 0, image: chilliPaneerPizzaImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1005, name: 'Chilli Paneer Pizza (Medium)', description: 'Paneer, Onion, Tomato, Red Paprika', price: 349, size: 'Medium', category: 'premiumPizza', quantity: 0, image: chilliPaneerPizzaImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1006, name: 'Chilli Paneer Pizza (Large)', description: 'Paneer, Onion, Tomato, Red Paprika', price: 529, size: 'Large', category: 'premiumPizza', quantity: 0, image: chilliPaneerPizzaImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },

{ id: 1007, name: 'Veggie Lover Pizza (Regular)', description: 'Onion, Capsicum, Mushroom, Tomato, Red Paprika', price: 199, size: 'Regular', category: 'premiumPizza', quantity: 0, image: veggieLoverPizzaImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1008, name: 'Veggie Lover Pizza (Medium)', description: 'Onion, Capsicum, Mushroom, Tomato, Red Paprika', price: 399, size: 'Medium', category: 'premiumPizza', quantity: 0, image: veggieLoverPizzaImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1009, name: 'Veggie Lover Pizza (Large)', description: 'Onion, Capsicum, Mushroom, Tomato, Red Paprika', price: 599, size: 'Large', category: 'premiumPizza', quantity: 0, image: veggieLoverPizzaImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },

{ id: 1010, name: 'Tandoori Lover Pizza (Regular)', description: 'Tomato, Paneer, Green Chili, Tandoori Sauce', price: 279, size: 'Regular', category: 'premiumPizza', quantity: 0, image: tandooriLoverPizzaImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1011, name: 'Tandoori Lover Pizza (Medium)', description: 'Tomato, Paneer, Green Chili, Tandoori Sauce', price: 479, size: 'Medium', category: 'premiumPizza', quantity: 0, image: tandooriLoverPizzaImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1012, name: 'Tandoori Lover Pizza (Large)', description: 'Tomato, Paneer, Green Chili, Tandoori Sauce', price: 679, size: 'Large', category: 'premiumPizza', quantity: 0, image: tandooriLoverPizzaImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },

{ id: 1013, name: 'Overloaded Pizza (Regular)', description: 'Onion, Capsicum, Red Paprika, Jalapeno, Spicy Flavour', price: 179, size: 'Regular', category: 'premiumPizza', quantity: 0, image: overloadedPizzaImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1014, name: 'Overloaded Pizza (Medium)', description: 'Onion, Capsicum, Red Paprika, Jalapeno, Spicy Flavour', price: 329, size: 'Medium', category: 'premiumPizza', quantity: 0, image: overloadedPizzaImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1015, name: 'Overloaded Pizza (Large)', description: 'Onion, Capsicum, Red Paprika, Jalapeno, Spicy Flavour', price: 519, size: 'Large', category: 'premiumPizza', quantity: 0, image: overloadedPizzaImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },

// Family Meals
{ id: 1016, name: 'Family Meal (Serves 4)', description: '2 Medium Pizzas, Pasta, Garlic Breads, 2 Cold Drinks', price: 799, category: 'familyMeal', quantity: 0, image: familyMealImage, Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1017, name: 'Family Meal (Serves 2)', description: '1 Medium Pizza, Pasta, Garlic Bread, Cold Drink', price: 449, category: 'familyMeal', quantity: 0, image: onefamilyMealImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },

// Garlic Breads
{ id: 1018, name: 'Stuffed Garlic Bread with Dip', description: 'Delicious Garlic Bread stuffed with cheese and served with dip', price: 110, category: 'breads', quantity: 0, image: garlicBreadImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },
{ id: 1019, name: 'Garlic Bread Stick with Dip', description: 'Crispy Garlic Bread sticks served with a dip', price: 79, category: 'breads', quantity: 0,  image: garlicBreadStickImage , Shopname: 'Pizza_Brown',shop: PizzaBrown },

// { id: 23, name: 'Desi Ghee Gajar Pak (500g)', nameHi: 'देसी घी गाजर पाक (आधा किलो)', description: 'Delicious ghee-based carrot sweet', descriptionHi: 'स्वादिष्ट घी से बनी गाजर की मिठाई', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 350, quantity: 0 },
// { id: 24, name: 'Desi Ghee Gajar Pak (1 KG)', nameHi: 'देसी घी गाजर पाक (पूरा किलो)', description: 'Delicious ghee-based carrot sweet', descriptionHi: 'स्वादिष्ट घी से बनी गाजर की मिठाई', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 700, quantity: 0 },
// { id: 43, name: 'Matar / Mathri (500g)', nameHi: 'मटर / मठरी (आधा किलो)', description: 'Savory fried snack', descriptionHi: 'नमकीन तली हुई मठरी', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'snacks', price: 80, quantity: 0, image: matarMathriHalfKg },
// { id: 44, name: 'Matar / Mathri (1 KG)', nameHi: 'मटर / मठरी (पूरा किलो)', description: 'Savory fried snack', descriptionHi: 'नमकीन तली हुई मठरी', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'snacks', price: 160, quantity: 0, image: matarMathriFullKg },

// { id: 29, name: 'Peda (500g)', nameHi: 'पेड़ा (आधा किलो)', description: 'Sweet and rich peda', descriptionHi: 'स्वादिष्ट और समृद्ध पेड़ा', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 330, quantity: 0 },
// { id: 30, name: 'Peda (1 KG)', nameHi: 'पेड़ा (पूरा किलो)', description: 'Sweet and rich peda', descriptionHi: 'स्वादिष्ट और समृद्ध पेड़ा', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 660, quantity: 0 },


// { id: 33, name: 'Rasgulla (500g)', nameHi: 'रसगुल्ला (आधा किलो)', description: 'Soft and spongy syrup-soaked balls', descriptionHi: 'मुलायम और चाशनी में डूबे हुए रसगुल्ले', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 120, quantity: 0 },
// { id: 34, name: 'Rasgulla (1 KG)', nameHi: 'रसगुल्ला (पूरा किलो)', description: 'Soft and spongy syrup-soaked balls', descriptionHi: 'मुलायम और चाशनी में डूबे हुए रसगुल्ले', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 240, quantity: 0 },

// { id: 35, name: 'Desi Ghee Imarti (500g)', nameHi: 'देसी घी इमरती (आधा किलो)', description: 'Crispy sweet made in pure ghee', descriptionHi: 'शुद्ध घी में बनी कुरकुरी मिठाई', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 260, quantity: 0 },
// { id: 36, name: 'Desi Ghee Imarti (1 KG)', nameHi: 'देसी घी इमरती (पूरा किलो)', description: 'Crispy sweet made in pure ghee', descriptionHi: 'शुद्ध घी में बनी कुरकुरी मिठाई', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 520, quantity: 0 },



// { id: 41, name: 'Balushahi (500g)', nameHi: 'बालूशाही (आधा किलो)', description: 'Flaky and sweet Balushahi', descriptionHi: 'खस्ता और मीठी बालूशाही', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 260, quantity: 0 },
// { id: 42, name: 'Balushahi (1 KG)', nameHi: 'बालूशाही (पूरा किलो)', description: 'Flaky and sweet Balushahi', descriptionHi: 'खस्ता और मीठी बालूशाही', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'sweets', price: 520, quantity: 0 },


// { id: 45, name: 'Besan Namkeen (500g)', nameHi: 'बेसन नमकीन (आधा किलो)', description: 'Crunchy and savory gram flour snack', descriptionHi: 'कुरकुरी बेसन से बनी नमकीन', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'snacks', price: 110, quantity: 0 },
// { id: 46, name: 'Besan Namkeen (1 KG)', nameHi: 'बेसन नमकीन (पूरा किलो)', description: 'Crunchy and savory gram flour snack', descriptionHi: 'कुरकुरी बेसन से बनी नमकीन', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'snacks', price: 220, quantity: 0 },

// { id: 47, name: 'Dahi (500g)', nameHi: 'दही (आधा किलो)', description: 'Fresh homemade yogurt', descriptionHi: 'ताजा घर का बना दही', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'dairy', price: 80, quantity: 0 },
// { id: 48, name: 'Dahi (1 KG)', nameHi: 'दही (पूरा किलो)', description: 'Fresh homemade yogurt', descriptionHi: 'ताजा घर का बना दही', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'dairy', price: 160, quantity: 0 },

// { id: 49, name: 'Paneer (500g)', nameHi: 'पनीर (आधा किलो)', description: 'Soft and fresh paneer', descriptionHi: 'मुलायम और ताजा पनीर', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'dairy', price: 250, quantity: 0 },
// { id: 50, name: 'Paneer (1 KG)', nameHi: 'पनीर (पूरा किलो)', description: 'Soft and fresh paneer', descriptionHi: 'मुलायम और ताजा पनीर', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'dairy', price: 500, quantity: 0 },

// { id: 51, name: 'Mawa (500g)', nameHi: 'मावा (आधा किलो)', description: 'Rich milk solids used in sweets', descriptionHi: 'मिठाईयों में इस्तेमाल होने वाला मावा', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'dairy', price: 330, quantity: 0 },
// { id: 52, name: 'Mawa (1 KG)', nameHi: 'मावा (पूरा किलो)', description: 'Rich milk solids used in sweets', descriptionHi: 'मिठाईयों में इस्तेमाल होने वाला मावा', shop: shivGanga,  Shopname: 'Shiv_Ganga', category: 'dairy', price: 660, quantity: 0 },
 // Burgers
{ id: 2001, name: 'Masala Grill Burger', nameHi: 'मसाला ग्रिल बर्गर', description: 'Spicy grilled burger with masala', descriptionHi: 'मसाला के साथ मसालेदार ग्रिल्ड बर्गर', price: 70, category: 'burger', quantity: 0, image: masalaGrillBurgerImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 2002, name: 'Maxican Burger', nameHi: 'मैक्सिकन बर्गर', description: 'Maxican style burger with tangy flavors', descriptionHi: 'खट्टे फ्लेवर के साथ मैक्सिकन शैली का बर्गर', price: 80, category: 'burger', quantity: 0, image: maxicanBurgerImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 2003, name: 'Paneer Pizza Burger', nameHi: 'पनीर पिज़्ज़ा बर्गर', description: 'Burger with a paneer and pizza twist', descriptionHi: 'पनीर और पिज़्ज़ा ट्विस्ट के साथ बर्गर', price: 80, category: 'burger', quantity: 0, image: paneerPizzaBurgerImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 2004, name: 'Veg Supreme Burger', nameHi: 'वेज सुप्रीम बर्गर', description: 'Loaded veggie supreme burger', descriptionHi: 'वेजी से भरा सुप्रीम बर्गर', price: 60, category: 'burger', quantity: 0, image: vegSupremeBurgerImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 2005, name: 'Maharaja Burger', nameHi: 'महाराजा बर्गर', description: 'Royal burger with rich flavors', descriptionHi: 'रॉयल बर्गर समृद्ध स्वाद के साथ', price: 100, category: 'burger', quantity: 0, image: maharajaBurgerImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 2006, name: 'Pizza Brown Spl Burger', nameHi: 'पिज्जा ब्राउन स्पेशल बर्गर', description: 'Signature burger of Pizza Brown', descriptionHi: 'पिज़्ज़ा ब्राउन का सिग्नेचर बर्गर', price: 100, category: 'burger', quantity: 0, image: pizzaBrownSplBurgerImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },

// Sandwiches
{ id: 3001, name: 'Cold Sandwich', nameHi: 'कोल्ड सैंडविच', description: 'Chilled and refreshing sandwich', descriptionHi: 'ठंडा और ताज़ा सैंडविच', price: 80, category: 'sandwich', quantity: 0, image: coldSandwichImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 3002, name: 'Sweetcorn Sandwich', nameHi: 'स्वीटकॉर्न सैंडविच', description: 'Sweetcorn and veggie-filled sandwich', descriptionHi: 'स्वीटकॉर्न और सब्ज़ियों से भरा सैंडविच', price: 90, category: 'sandwich', quantity: 0, image: sweetcornSandwichImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 3003, name: 'Paneer Sandwich', nameHi: 'पनीर सैंडविच', description: 'Delicious paneer sandwich', descriptionHi: 'स्वादिष्ट पनीर सैंडविच', price: 100, category: 'sandwich', quantity: 0, image: paneerSandwichImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 3004, name: 'Veg Grill Sandwich', nameHi: 'वेज ग्रिल सैंडविच', description: 'Grilled veggie sandwich', descriptionHi: 'ग्रिल्ड सब्जियों से बना सैंडविच', price: 100, category: 'sandwich', quantity: 0, image: vegGrillSandwichImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 3005, name: 'Tandoori Sandwich', nameHi: 'तंदूरी सैंडविच', description: 'Sandwich with tandoori flavor', descriptionHi: 'तंदूरी स्वाद वाला सैंडविच', price: 120, category: 'sandwich', quantity: 0, image: tandooriSandwichImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },
{ id: 3006, name: 'Super Salad Sandwich', nameHi: 'सुपर सलाद सैंडविच', description: 'Healthy salad-filled sandwich', descriptionHi: 'स्वस्थ सलाद से भरा सैंडविच', price: 120, category: 'sandwich', quantity: 0, image: superSaladSandwichImage, Shopname: 'Pizza_Brown', shop: PizzaBrown },

// sweet
{ id: 37, name: 'Barfi (500g)', nameHi: 'बर्फी (आधा किलो)', description: 'Classic sweet barfi', descriptionHi: 'क्लासिक बर्फी मिठाई', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 280, quantity: 0, image: barfiHalfKg },
// { id: 38, name: 'Barfi (1 KG)', nameHi: 'बर्फी (पूरा किलो)', description: 'Classic sweet barfi', descriptionHi: 'क्लासिक बर्फी मिठाई', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 560, quantity: 0, image: barfiFullKg },
{ id: 39, name: 'Gulab Jamun (500g)', nameHi: 'गुलाब जामुन (आधा किलो)', description: 'Juicy fried milk balls soaked in syrup', descriptionHi: 'रसीले तले हुए दूध के गोले जो चाशनी में डूबे होते हैं', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 140, quantity: 0, image: gulabJamunHalfKg },
// { id: 40, name: 'Gulab Jamun (1 KG)', nameHi: 'गुलाब जामुन (पूरा किलो)', description: 'Juicy fried milk balls soaked in syrup', descriptionHi: 'रसीले तले हुए दूध के गोले जो चाशनी में डूबे होते हैं', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 280, quantity: 0, image: gulabJamunFullKg },
{ id: 41, name: 'Besan Laddu (500g)', nameHi: 'बेसन लड्डू (आधा किलो)', description: 'Sweet laddus made from gram flour', descriptionHi: 'बेसन से बने स्वादिष्ट लड्डू', shop: shivGanga , Shopname: 'Shiv_Ganga', category: 'sweets', price: 100, quantity: 0, image: besanLadduHalfKg },
// { id: 42, name: 'Besan Laddu (1 KG)', nameHi: 'बेसन लड्डू (पूरा किलो)', description: 'Sweet laddus made from gram flour', descriptionHi: 'बेसन से बने स्वादिष्ट लड्डू', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 200, quantity: 0, image: besanLadduFullKg },
{ id: 43, name: 'Boondi Laddu (500g)', nameHi: 'बूंदी लड्डू (आधा किलो)', description: 'Sweet boondi laddus', descriptionHi: 'स्वादिष्ट बूंदी के लड्डू', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 90, quantity: 0, image: boondiLadduHalfKg },
// { id: 44, name: 'Boondi Laddu (1 KG)', nameHi: 'बूंदी लड्डू (पूरा किलो)', description: 'Sweet boondi laddus', descriptionHi: 'स्वादिष्ट बूंदी के लड्डू', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 180, quantity: 0, image: boondiLadduFullKg },
{ id: 45, name: 'Kalakand (500g)', nameHi: 'कलाकंद (आधा किलो)', description: 'Soft and creamy milk sweet', descriptionHi: 'मुलायम और क्रीमी दूध से बनी मिठाई', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 280, quantity: 0, image: kalakandFullKg },
// { id: 46, name: 'Kalakand (1 KG)', nameHi: 'कलाकंद (पूरा किलो)', description: 'Soft and creamy milk sweet', descriptionHi: 'मुलायम और क्रीमी दूध से बनी मिठाई', shop: shivGanga, Shopname: 'Shiv_Ganga', category: 'sweets', price: 560, quantity: 0, image: kalakandFullKg },

  
   
    
  ]);

 
  const [name, setName] = useState('');
  const [tableNumber, setTableNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('all');
  const [isHindi, setIsHindi] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isBasketOpen, setIsBasketOpen] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(''); // Payment method state

  const [successMessage, setSuccessMessage] = useState(false); // For showing success message
  const [orderNumber, setOrderNumber] = useState(''); // Store order number for success message
  const [orderDetails, setOrderDetails] = useState(''); // Store order details for success message
  const [currentClock, setCurrentClock] = useState(null);
  const [nameError, setNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [contactError, setContactError] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [isSending, setIsSending] = useState(false);
  
  // Add a state to track the selected shop
  const [Shopname, setShopname] = useState('all');

  const validateForm = () => {
    let isValid = true;

    if (!name.trim()) {
      setNameError(isHindi ? 'कृपया नाम दर्ज करें।' : 'Please enter your name.');
      isValid = false;
    } else {
      setNameError('');
    }

    if (!tableNumber.trim()) {
      setAddressError(isHindi ? 'कृपया पता दर्ज करें।' : 'Please enter your address.');
      isValid = false;
    } else {
      setAddressError('');
    }

    const phonePattern = /^\d{10}$/;
    if (!contactNumber.match(phonePattern)) {
      setContactError(isHindi ? 'कृपया मान्य संपर्क नंबर दर्ज करें।' : 'Please enter a valid 10-digit contact number.');
      isValid = false;
    } else {
      setContactError('');
    }

    if (!paymentMethod) {
      setPaymentError(isHindi ? 'कृपया भुगतान विधि चुनें।' : 'Please select a payment method.');
      isValid = false;
    } else {
      setPaymentError('');
    }

    return isValid;
  };

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const currentHour = now.getHours();

      if (currentHour >= 19 || currentHour < 13) {
        // Show Lunch Clock between 7 PM and 1 PM
        setCurrentClock("lunch");
      } else if (currentHour >= 13 && currentHour < 19) {
        // Show Evening Clock between 1 PM and 7 PM
        setCurrentClock("evening");
      }
    };

    // Update clock on component mount
    updateClock();

    // Set an interval to check and update every minute
    const intervalId = setInterval(updateClock, 60000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  

// Time configuration for lunch and dinner
const lunchOrderStartTime = 11; // 11 AM
const lunchOrderEndTime = 13; // 1 PM
const lunchDeliveryEndTime = 14; // 2 PM

const dinnerOrderStartTime = 17; // 5 PM
const dinnerOrderEndTime = 19; // 7 PM
const dinnerDeliveryEndTime = 20; // 8 PM

const [showPopup, setShowPopup] = useState(false);  // To handle the popup display
const [isOrderTime, setIsOrderTime] = useState(false);
const [isDeliveryTime, setIsDeliveryTime] = useState(false);
const [currentTime, setCurrentTime] = useState(new Date()); // Store current time
const styles = {
  container: {
    backgroundColor: '#f5e6cc', // Light brown background
    padding: '20px',
    borderRadius: '8px',
    fontFamily: "'Arial', sans-serif",
  },
  span: {
    color: '#d35400', // Dark orange color for keywords
    fontWeight: 'bold',
  },
  text: {
    color: '#4b3832', // Dark brown for regular text
  },
};

const [timeSlot, setTimeSlot] = useState('');

useEffect(() => {
  const currentTime = new Date();
  const currentHours = currentTime.getHours();

  // Determine if it's order-taking time
  if ((currentHours >= 11 && currentHours < 13) || (currentHours >= 17 && currentHours < 19)) {
    setIsOrderTime(true);
    setIsDeliveryTime(false);

    if (currentHours >= 11 && currentHours < 13) {
      setTimeSlot('lunch');
    } else if (currentHours >= 17 && currentHours < 19) {
      setTimeSlot('evening');
    }
  } 
  // Determine if it's delivery time
  else if ((currentHours >= 13 && currentHours < 14) || (currentHours >= 19 && currentHours < 20)) {
    setIsOrderTime(false);
    setIsDeliveryTime(true);

    if (currentHours >= 13 && currentHours < 14) {
      setTimeSlot('lunch');
    } else if (currentHours >= 19 && currentHours < 20) {
      setTimeSlot('evening');
    }
  } 
  // Outside of both order-taking and delivery times
  else {
    setIsOrderTime(false);
    setIsDeliveryTime(false);
    setTimeSlot('');
  }
}, []);

// Function to check if it's within the allowed times for order taking or delivery
const checkOrderAndDeliveryTime = () => {
  const currentHour = currentTime.getHours();

  // Check for lunch time
  if (currentHour >= lunchOrderStartTime && currentHour < lunchOrderEndTime) {
    setIsOrderTime(true); // Taking lunch orders
    setIsDeliveryTime(false); // Not delivering yet
  } else if (currentHour >= lunchOrderEndTime && currentHour < lunchDeliveryEndTime) {
    setIsOrderTime(false); // No more lunch orders
    setIsDeliveryTime(true); // Lunch delivery time
  } 
  // Check for dinner time
  else if (currentHour >= dinnerOrderStartTime && currentHour < dinnerOrderEndTime) {
    setIsOrderTime(true); // Taking dinner orders
    setIsDeliveryTime(false); // Not delivering yet
  } else if (currentHour >= dinnerOrderEndTime && currentHour < dinnerDeliveryEndTime) {
    setIsOrderTime(false); // No more dinner orders
    setIsDeliveryTime(true); // Dinner delivery time
  } else {
    setIsOrderTime(false); // Outside of order time
    setIsDeliveryTime(false); // Outside of delivery time
  }
};

// useEffect to check the time when the component mounts and every minute thereafter
useEffect(() => {
  checkOrderAndDeliveryTime();
  const timer = setInterval(() => {
    setCurrentTime(new Date());
    
  }, 60000); // Update every minute

  return () => clearInterval(timer); // Cleanup the timer when the component unmounts
}, );


const [lastModifiedItem, setLastModifiedItem] = useState({ id: null, message: '' });
  

 

  const UpiPayment = ({ grandTotal }) => {
    

    const upiId = "8168197978@ptyes";
    
  
  
  
    const upiLink = `upi://pay?pa=${upiId}&pn=PaperPalace&am=${grandTotal}&tn=Payment for Clickthali Food`;
  
    return (
      <div className="upi-container">
        <div id='pay' className="payment-content">
          <h1>Thankyou for choosing us</h1>
         
            <div id="upiDetails">
              <h2>Send Money via UPI</h2>
              <p>Scan the QR code below or click the button to make a payment of ₹{grandTotal}:</p>
              {/* <p>GST number: VDQOR1222OVCNNF</p> */}
              <div id="qrcode">
                <QRCode value={upiLink} size={200} />
              </div>
              <a href={upiLink} className="c5">
                Click here to pay via UPI
              </a>
              <p>📲 After Payment:</p>
              <p>
                💳 <strong>Please share a screenshot of your payment confirmation on WhatsApp.<br /> 
                Our team will contact you for order confirmation. Rest assured, your funds are secure with us.</strong>
              </p>
            </div>

          {/* Add the DownloadCard component here */}
<DownloadCard />
        </div>
      </div>
    );
  };
  
  const handleAdd = (item) => {
    setMenuItems(menuItems.map(i =>
      i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
    ));
    setLastModifiedItem({ id: item.id, message: `${item.name} added to cart` });
    setTimeout(() => setLastModifiedItem(''), 2000); // Clear message after 2 seconds
  };

  const handleRemove = (item) => {
    setMenuItems(menuItems.map(i =>
      i.id === item.id && i.quantity > 0 ? { ...i, quantity: i.quantity - 1 } : i
    ));
    setLastModifiedItem({ id: item.id, message: `${item.name} removed from cart` });
    setTimeout(() => setLastModifiedItem(''), 2000); // Clear message after 2 seconds
  };


  // Completely delete item from cart (set quantity to 0 or remove it)
  const handleDelete = (item) => {
    setMenuItems(menuItems.map(i =>
      i.id === item.id ? { ...i, quantity: 0 } : i
    ));
  };

  const calculateTotal = () => {
    return menuItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };


  
  const handleCheckout = async () => {
    if (!isOrderTime) {
      setShowPopup(true); // Show popup if it's not order time
    } else {
      if (validateForm()) {
        // Create an array of objects for the order items
        const orderItems = menuItems
          .filter(item => item.quantity > 0)
          .map(item => ({
            itemName: isHindi ? item.nameHi : item.name,
            price: item.price,
            quantity: item.quantity,
          }));
  
        // Calculate total amounts and apply delivery cost
        const totalAmount = calculateTotal();
        const deliveryCost = 30; // Delivery cost as a number
        const grandTotal = totalAmount + deliveryCost;
  
        // Generate a random 6-digit order number
        const generatedOrderNumber = Math.floor(100000 + Math.random() * 900000).toString();
        setOrderNumber(generatedOrderNumber);
        setOrderDetails(orderItems); // Store the order items for UI rendering
  
        // Get the current date and time
        const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
        const time = new Date().toLocaleTimeString('en-GB'); // HH:MM:SS format
  
        // Build the order data object to send to the server
        const orderData = {
          name: name,
          table: tableNumber, // 'table' changed to 'tableNumber' for clarity
          contactNumber: contactNumber,
          order: orderItems, // Sending order as an array of objects
          total: totalAmount, // Keep it as a number
          deliveryCost: deliveryCost, // Number
          grandTotal: grandTotal, // Number
          paymentMethod: paymentMethod,
          orderNumber: generatedOrderNumber,
          date: date,
          time: time,
        };
  
        console.log('Order Data:', orderData); // For debugging
        setIsSending(true); // Start rocket animation
  
        try {
          // Send order data to the server
          const response = await axios.post('https://ctserver.vercel.app/api/orders', orderData);
          console.log('API Response:', response.data);
  
          // Show success message if order is submitted
          setSuccessMessage(true);
          setIsSending(false); // Stop the rocket animation
  
        } catch (error) {
          const errorMessage = error?.response?.data?.message || error?.message || 'Unknown error';
          alert(`Failed to submit order. Error: ${errorMessage}`);
          console.error('Error submitting order:', error);
          setIsSending(false); // Stop the rocket animation even on failure
        }
    
        return grandTotal;
      }
    }
  };
  
  

// Calculate grand total with delivery cost
const grandTotal = calculateTotal() + 30;

// Filter items based on category and search term
const filteredItems = menuItems.filter(item => {
    return (category === 'all' || item.category === category) && (Shopname === 'all' || item.Shopname === Shopname) &&
      (item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.description.toLowerCase().includes(searchTerm.toLowerCase()));
});


  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
      
      
      <header className="App-header">
 
   

        <div className="wedding-board">
       
        <img src={logo} alt="Logo" className="header-logo" />
          <h1>{isHindi ? 'क्लिकथाली' : 'Clickthali '}</h1>
          
          <div style={styles.container}>
      <p style={styles.text}>
        <span style={styles.span}>Lunch order:</span> 11 AM - 1 PM, with <span style={styles.span}>delivery</span> from 1 PM - 2 PM.
      </p>
      <p style={styles.text}>
        <span style={styles.span}>Evening order:</span> 5 PM - 7 PM, with <span style={styles.span}>delivery</span> from 7 PM - 8 PM.
      </p>
    </div>
    <div>
      <h1>Order Timings</h1>
      {currentClock === "lunch" && (
        <CountdownClock startTime="11:00" endTime="13:00" /> // Lunch Order Window
      )}
      {currentClock === "evening" && (
        <CountdownClock startTime="17:00" endTime="19:00" /> // Evening Order Window
      )}
    </div>
          <div className="time-message">
      {isOrderTime && (
        <p>
          {isHindi
            ? timeSlot === 'lunch'
              ? 'ऑर्डर लेने का समय: 11 AM से 1 PM (दोपहर का खाना)'
              : 'ऑर्डर लेने का समय: 5 PM से 7 PM (शाम)'
            : timeSlot === 'lunch'
              ? 'Order taking Morning time: 11 AM to 1 PM (Lunch)'
              : 'Order taking Evening time: 5 PM to 7 PM (Evening)'
          }
        </p>
      )}
      {isDeliveryTime && (
        <p>
          {isHindi
            ? timeSlot === 'lunch'
              ? 'डिलीवरी का समय: 1 PM से 2 PM (दोपहर का खाना)'
              : 'डिलीवरी का समय: 7 PM से 8 PM (शाम)'
            : timeSlot === 'lunch'
              ? 'Delivery time: 1 PM to 2 PM (Lunch)'
              : 'Delivery time: 7 PM to 8 PM (Evening)'
          }
        </p>
      )}
      {!isOrderTime && !isDeliveryTime && (
        <p>
          {isHindi
            ? 'हम अभी ऑर्डर स्वीकार नहीं कर रहे हैं। कृपया दिए गए ऑर्डर समय में प्रयास करें।'
            : 'We are not accepting orders right now. Please try during the order-taking times.'
          }
        </p>
      )}
    </div>
         <div className={`basket ${isBasketOpen ? 'open' : 'closed'}`}>
  <div className="basket-header">
    <h2>{isHindi ? 'आपकी टोकरी' : 'Your Basket'}</h2>
    
    <button  onClick={() => setIsBasketOpen(!isBasketOpen)} >
    


      {isBasketOpen ? < FaTimes/> : <FaChevronDown />}
    </button>
  </div>
 
  {/* Cart Icon with Item Count */}
  <div className="basket-header-icon" onClick={() => setIsBasketOpen(!isBasketOpen)}>
    <FaShoppingBasket />
    {menuItems.reduce((total, item) => total + item.quantity, 0) > 0 && (
      <span className="cart-count">
        {menuItems.reduce((total, item) => total + item.quantity, 0)}
      </span>
    )}
  </div>

  <br></br>
  <button class="checkout-button" onClick={() => document.getElementById('checkout').scrollIntoView({ behavior: 'smooth' })}>
  {isHindi ? 'चेकआउट' : 'Checkout'}
</button>
<button class="payment-button" onClick={() => document.getElementById('pay').scrollIntoView({ behavior: 'smooth' })}>
  {isHindi ? 'बास्केट बिल भुगतान' : 'Basket bill payment'}
</button>

  {isBasketOpen && (
    <div className="basket-content">
      {menuItems.filter(item => item.quantity > 0).map(item => (
        <div key={item.id} className="basket-item">
          <img src={item.image} alt={item.name} />
          <div className="basket-item-details">
            <div className="basket-item-name">{isHindi ? item.nameHi : item.name}</div>
            <div className="basket-item-price">{`₹${item.price} x ${item.quantity}`}</div>
          </div>
          <div className="basket-item-actions">
            <button onClick={() => handleRemove(item)}><FaMinus /></button>
            <span>{item.quantity}</span>
            <button onClick={() => handleAdd(item)}><FaPlus /></button>
            <button onClick={() => handleDelete(item)}><FaTrash /></button>
          </div>
        </div>
      ))}
      <div className="basket-footer">
    <h3>{isHindi ? `कुल: ₹${calculateTotal()}` : `Total: ₹${calculateTotal()}`}</h3>
    <div className="basket-summary">
        <p>{isHindi ? `डिलीवरी शुल्क: ₹30` : `Delivery Cost: ₹30`}</p>
        <h3>{isHindi ? `अंतिम राशि भुगतान के लिए: ₹${calculateTotal() + 30}` : `Final Amount to Pay: ₹${calculateTotal() + 30}`}</h3>
    </div>
    <button onClick={() => document.getElementById('checkout').scrollIntoView({ behavior: 'smooth' })}>
        {isHindi ? 'ऑर्डर करें' : 'Checkout'}
    </button>
</div>

    </div>
  )}
</div>


 
          <div className="lighting"></div>
        </div>
      </header>
      <SponsoredBanner/>
      <LaunchAnnouncement />
      {/* <ThaliBuilder/> */}
      <p>{'Book your order online or call us now!'}</p>
      <p>
      <a
        href={`https://wa.me/918168585727?text=Hi,%20I%20am%20interested%20in%20your%20restaurant%20app%20services.`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ 
          textDecoration: 'none', 
          background: 'transparent', 
          border: 'none', 
          cursor: 'pointer',
        }}
      >
        <img 
          src= {supporticon}  // replace with your image path
          alt="click here"
          style={{
            width: '300px',  // Adjust size
            height: 'auto', 
            
          }}
        />
      </a>
    </p>
  
  
    <h3>{isHindi ? 'अपनी पसंदीदा दुकान चुनें जहाँ भोजन आपके स्वाद के अनुकूल हो' : 'Pick your favorite eatery where the food suits your taste'}</h3>

  <div id="topheading" className="dropdown-category">
  
  <select
    value={Shopname} 
    onChange={(e) => setShopname(e.target.value)}
    className="shop-dropdown"
    title={isHindi ? 'दुकान चुनें' : 'Select a Shop'} // Tooltip on hover
  >
    <option value="" disabled>
      {isHindi ? 'दुकान चुनें' : 'Select a Shop'}
    </option>
    <option value="all">{isHindi ? 'सभी' : 'All'}</option>
    <option value="Shyam_ji_dhaba">{isHindi ? 'श्याम जी ढाबा' : 'Shyam ji Dhaba'}</option>
    <option value="Pizza_Brown">{isHindi ? 'पिज़्ज़ा ब्राउन' : 'Pizza Brown'}</option>
    <option value="Shiv_Ganga">{isHindi ? 'शिव गंगा मिष्ठान भंडार' : 'Shiv Ganga Misthan Bhandar'}</option>
  </select>
</div>

      <div className="controls">
        <button onClick={() => setIsHindi(!isHindi)}>{isHindi ? 'English' : 'हिंदी'}</button>
        <button onClick={() => setIsDarkMode(!isDarkMode)}>{isDarkMode ? (isHindi ? 'प्रकाश मोड' : 'Light Mode') : (isHindi ? 'डार्क मोड' : 'Dark Mode')}</button>
        <input
          type="text"
          placeholder={isHindi ? 'खोजें...' : 'Search...'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div id="topheading" className="categories">
      <div className="category">
        <div onClick={() => setCategory('all')}>
          <FaUtensils size={50} />
        </div>
        <button onClick={() => setCategory('all')}>
          {isHindi ? 'सभी' : 'All'}
        </button>
      </div>
      <div className="categories">
  <div className="category">
    <div onClick={() => setCategory('maincourse')}>
      <GiIndianPalace size={50} />
    </div>
    <button onClick={() => setCategory('maincourse')}>
      {isHindi ? 'मुख्य सब्जी' : 'Main Course'}
    </button>
  </div>
  <div className="category">
  <div onClick={() => setCategory('premiumPizza')}>
    <FaPizzaSlice size={50} />
  </div>
  <button onClick={() => setCategory('premiumPizza')}>
    {isHindi ? 'प्रीमियम पिज़्ज़ा' : 'Premium Pizza'}
  </button>
</div>
  {/* Burgers Section */}
  <div className="category">
        <div onClick={() => setCategory('burger')}>
          <FaHamburger size={50} />
        </div>
        <button onClick={() => setCategory('burger')}>
          {isHindi ? 'बर्गर' : 'Burgers'}
        </button>
      </div>
      
      {/* Sandwiches Section */}
      <div className="category">
        <div onClick={() => setCategory('sandwich')}>
          <FaBreadSlice size={50} />
        </div>
        <button onClick={() => setCategory('sandwich')}>
          {isHindi ? 'सैंडविच' : 'Sandwiches'}
        </button>
      </div>
      <div className="category">
  <div onClick={() => setCategory('sweets')}>
    <FaIceCream size={50} /> {/* You can use an icon that best represents sweets */}
  </div>
  <button onClick={() => setCategory('sweets')}>
    {isHindi ? 'मिठाइयाँ' : 'Sweets'}
  </button>
</div>

  <div className="category">
    <div onClick={() => setCategory('bread')}>
      <FaBreadSlice size={50} />
    </div>
    <button onClick={() => setCategory('bread')}>
      {isHindi ? 'रोटी' : 'Roti'}
    </button>
  </div>
  <div className="category">
    <div onClick={() => setCategory('special_thali')}>
      <FaUtensils size={50} />
    </div>
    <button onClick={() => setCategory('special_thali')}>
      {isHindi ? 'विशेष थाली' : 'Special Thali'}
    </button>
  </div>
 


      </div>
 
    </div>
    
    <div className="thali-section">
  <div className="thali-items-container">
  {/* Special Thali Items with 20% Off */}
  {menuItems.filter(item => item.category === "specialthali").map(item => (
    <div className="thali-item-wrapper" key={item.id}>
      <MenuItem 
        item={item} 
        onAdd={handleAdd} 
        onRemove={handleRemove} 
        isHindi={isHindi} 
        message={lastModifiedItem.id === item.id ? lastModifiedItem.message : ''}
      />
      <div className="special-offer-badge">
        {isHindi ? "20% छूट" : "20% Off"}
      </div>
    </div>
  ))}

  
</div>


  {/* Regular Menu Section */}
 
  <div className="menu-list">
    {filteredItems.map(item => (
      <MenuItem
        key={item.id}
        item={item}
        onAdd={handleAdd}
        onRemove={handleRemove}
        isHindi={isHindi}
        message={lastModifiedItem.id === item.id ? lastModifiedItem.message : ''}

      />
      
    ))}
    
  </div>
  <h2>{isHindi ? 'थाली (विशेष प्रस्ताव)' : 'Thali (Special Offer)'}</h2>
  <div className='specialContainer'>
  
  {/* Regular Thali Items with 30% Off */}
  {menuItems.filter(item => item.category === "thali").map(item => (
    <div className="thali-item-wrapper" key={item.id}>
      <MenuItem 
        item={item} 
        onAdd={handleAdd} 
        onRemove={handleRemove} 
        isHindi={isHindi} 
        message={lastModifiedItem.id === item.id ? lastModifiedItem.message : ''}
      />
      <div className="special-offer-badge">
        {isHindi ? "30% छूट" : "30% Off"}
      </div>
    </div>
  ))}
</div>
</div>
    


        
      
     <div>
      {/* Bottom-right button icon attached to heading ID */}
      <button className="heading-icon" onClick={() => scrollToSection('topheading')}>
      <FaArrowUp /> {/* Up arrow icon */}
      </button>
      
    
</div>
     
<div id="checkout" className="checkout-form">
    <h2>{isHindi ? 'चेकआउट' : 'Checkout'}</h2>
    <input
        type="text"
        placeholder={isHindi ? 'नाम' : 'Name'}
        value={name}
        onChange={(e) => setName(e.target.value)}
    />
    {nameError && <p className="error-message">{nameError}</p>}
    <input
        type="text"
        placeholder={isHindi ? 'पता (अभी तक हम केवल रोहतक शहरी क्षेत्रों में डिलीवरी करते हैं)' : 'Address (As of now we deliver only in Rohtak urban areas)'}
        value={tableNumber}
        onChange={(e) => setTableNumber(e.target.value)}
        
    />
     {addressError && <p className="error-message">{addressError}</p>}
    <p>Area Pincode: 124001</p>
    <input
        type="text"
        placeholder={isHindi ? 'संपर्क नंबर (10 अंकों)' : 'Contact Number (10 digits)'}
        value={contactNumber}
        onChange={(e) => setContactNumber(e.target.value)}
        maxLength="10"
        pattern="\d{10}"
        title={isHindi ? 'कृपया 10 अंकों का संपर्क नंबर दर्ज करें।' : 'Please enter a 10-digit contact number.'}
    />
    {contactError && <p className="error-message">{contactError}</p>}

    <div className='payment-option'>
        <h4>Select Payment Method:</h4>
        <label>
            <input
                type="radio"
                value="Cash"
                checked={paymentMethod === "Cash"}
                onChange={(e) => setPaymentMethod(e.target.value)}
            />
            Pay on Delivery
        </label>
        {paymentError && <p className="error-message">{paymentError}</p>}
        <label>
            <input
                type="radio"
                value="UPI"
                checked={paymentMethod === "UPI"}
                onChange={(e) => setPaymentMethod(e.target.value)}
            />
            UPI (if paid)
        </label>
       
    </div>

 {/* Success Message */}

{successMessage && (
  <div className="thankYou">
    <h2>🎉 Congratulations!</h2>
    <p>Thank you, {name}, for submitting your order!</p>
    
    {/* Highlighted Order Number */}
    <p style={{ fontSize: '24px', color: '#FF5733', fontWeight: 'bold' }}>
      Your order number is {orderNumber}.
    </p>
    
    <p>We will serve your food fresh within next 1 hr after closing order Window. 😊</p>
    <p><strong>Order Details:</strong> {orderDetails && orderDetails.length > 0 && (
  <div>
    <h3>Your Order:</h3>
    {orderDetails.map((item, index) => (
      <div key={index}>
        {item.itemName} (₹{item.price}) x {item.quantity}
      </div>
    ))}
  </div>
)}
</p>
  </div>
)}
<br />


<div className="time-message">
  {isOrderTime && (
    <p>
      {isHindi
        ? currentTime.getHours() < 13 
          ? 'ऑर्डर लेने का समय: 11 AM से 1 PM' 
          : 'ऑर्डर लेने का समय: 5 PM से 7 PM'
        : currentTime.getHours() < 13
          ? 'Order taking Morning time: 11 AM to 1 PM'
          : 'Order taking Evening time: 5 PM to 7 PM'}
    </p>
  )}
  
  {isDeliveryTime && (
    <p>
      {isHindi
        ? currentTime.getHours() < 14 
          ? 'डिलीवरी का समय: 1 PM से 2 PM' 
          : 'डिलीवरी का समय: 7 PM से 8 PM'
        : currentTime.getHours() < 14
          ? 'Delivery time: 1 PM to 2 PM'
          : 'Delivery time: 7 PM to 8 PM'}
    </p>
  )}
  
  {!isOrderTime && !isDeliveryTime && (
    <p>
      {isHindi
        ? currentTime.getHours() < 13 
          ? 'हम अभी ऑर्डर स्वीकार नहीं कर रहे हैं। कृपया 11 AM के बाद प्रयास करें।' 
          : 'हम अभी ऑर्डर स्वीकार नहीं कर रहे हैं। कृपया 5 PM के बाद प्रयास करें।'
        : currentTime.getHours() < 13
          ? 'We are not accepting orders right now. Please try after 11 AM.'
          : 'We are not accepting orders right now. Please try after 5 PM.'}
    </p>
  )}
</div>

{/* Popup Modal when trying to order outside of allowed time */}
{!isOrderTime && !isDeliveryTime && showPopup && (
  <div className="order-popup">
    <div className="popup-board">
      <h2>{isHindi ? 'रेस्टोरेंट बंद है' : 'Restaurant Closed'}</h2>
      <p>
        {isHindi
          ? currentTime.getHours() < 13 
            ? 'हम ऑर्डर लेने का समय 11 AM से 1 PM के बीच स्वीकार करते हैं। कृपया बाद में प्रयास करें।'
            : 'हम ऑर्डर लेने का समय 5 PM से 7 PM के बीच स्वीकार करते हैं। कृपया बाद में प्रयास करें।'
          : currentTime.getHours() < 13
            ? 'We accept orders between 11 AM and 1 PM. Please try later.'
            : 'We accept orders between 5 PM and 7 PM. Please try later.'}
      </p>
      <button className="close-popup" onClick={() => setShowPopup(false)}>
        {isHindi ? 'बंद करें' : 'Close'}
      </button>
    </div>
  </div>
)}


<div>
      {/* Send Order Button */}
      {!successMessage && isOrderTime && (
        <button className="sendorder" onClick={handleCheckout}>
          {isSending ? (
            <FaRocket className="rocket-icon" /> // Show animated rocket icon
          ) : (
            isHindi ? 'भेजें' : 'Send Order'
          )}
        </button>
      )}

      {/* Success Message */}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>


        {/* <Route path="/admin" element={<AdminPanel />} /> */}
        <UpiPayment grandTotal={grandTotal} />
      </div>
      <footer style={{ textAlign: 'center', marginTop: '20px' }}>
        <img src={hooshoplogo} alt="HooShop Logo" style={{ width: '150px', marginBottom: '10px' }} />
        <p>Powered by HooShop</p>
        <p>Looking to build a custom app for your restaurant? Contact us today!</p>
        <p>Email: <a href="mailto:hooshopservice@gmail.com">hooshopservice@gmail.com</a> | Phone: +91 9817409607</p>
      </footer>
    </div>
    
  );
};

function scrollToSection(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
// function startRotation() {
//   var button = document.getElementById('checkoutBtn');
//   button.innerHTML = ""; // Remove text
//   button.classList.add('rotate'); // Add the rotating circle effect
// }

export default App;