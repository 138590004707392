import React, { useState, useEffect } from "react";
import frontCardImage from "./images/card-front.png";
import backCardImage from "./images/card-back.png";
import vCardImage from "./images/VisitingCard.png";
import supporticon from "./images/supporticon.png";
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn, FaWhatsapp} from "react-icons/fa"; // FontAwesome icons
import { SiGmail } from "react-icons/si";  // Import Gmail icon


const DownloadCard = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [showFront, setShowFront] = useState(true);

  // Auto switch between front and back every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFront((prevShowFront) => !prevShowFront);
    }, 5000); // 5 seconds interval
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = vCardImage;
    link.download = "VisitingCard.png";
    link.click();
  };

  const handleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const handleImageSwitch = (e) => {
    e.stopPropagation();
    setShowFront(!showFront);
  };

  const handleOutsideClick = () => {
    setIsZoomed(false);
  };

  const cardImage = showFront ? frontCardImage : backCardImage;

  return (
    <div style={styles.cardContainer}>
      <p>{'|| Contact us: +91 8168585727 || support@clickthali.com ||'}</p>
      <p>
        <a
          href={`https://wa.me/918168585727?text=Hi,%20I%20am%20interested%20in%20your%20restaurant%20app%20services.`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', background: 'transparent', border: 'none', cursor: 'pointer' }}
        >
          <img 
            src={supporticon} 
            alt="Click for Support"
            style={{
              width: '300px', 
              height: 'auto',
            }}
          />
        </a>
      </p>

      {/* Social Media Links */}
      <div style={styles.socialContainer}>
        <a
          href="https://www.facebook.com/share/3iAdvgWE51dy56ip/?mibextid=qi2Omg"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.socialLink}
        >
          <FaFacebookF style={styles.icon} />
        </a>
        <a
          href="https://www.instagram.com/clickthali99?igsh=MXV6Y2VraWlhbGVheA==
"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.socialLink}
        >
          <FaInstagram style={styles.icon} />
        </a>
        <a
          href="mailto:support@clickthali.com"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.socialLink}
        >
          <SiGmail style={styles.icon} />
        </a>
        {/* LinkedIn Link */}
  <a
    href="https://www.linkedin.com/company/clickthali/"
    target="_blank"
    rel="noopener noreferrer"
    style={styles.socialLink}
  >
    <FaLinkedinIn style={styles.icon} />
  </a>
  {/* YouTube Link */}
  <a
    href="https://www.youtube.com/@clickthalidotcom"
    target="_blank"
    rel="noopener noreferrer"
    style={styles.socialLink}
  >
    <FaYoutube style={styles.icon} />
  </a>
   {/* WhatsApp Channel Link */}
   <a
    href="https://whatsapp.com/channel/0029VarFh31KgsNqv1wg8A0s"
    target="_blank"
    rel="noopener noreferrer"
    style={styles.socialLink}
  >
    <FaWhatsapp style={styles.icon} />
  </a>
      </div>

      {isZoomed && (
        <div style={styles.overlay} onClick={handleOutsideClick}>
          <span style={styles.closeButton} onClick={handleZoom}>&times;</span>
          <div style={styles.zoomedCardImageWrapper} onClick={handleImageSwitch}>
            <img src={cardImage} alt="Visiting Card" style={styles.zoomedCardImage} />
          </div>
        </div>
      )}

      <div style={styles.cardImageWrapper} onClick={handleZoom}>
        <img src={cardImage} alt="Visiting Card" style={styles.cardImage} />
      </div>

      <button style={styles.downloadButton} onClick={handleDownload}>
        Download Visiting Card
      </button>
    </div>
  );
};

const styles = {
  cardContainer: {
    border: "0px solid #ccc",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
    boxSizing: "border-box",
  },
  cardImageWrapper: {
    position: "relative",
    width: "100%",
    height: "auto",
    marginBottom: "10px",
    cursor: "pointer",
  },
  cardImage: {
    width: "100%",
    height: "auto", // Ensures image adjusts to container size
    objectFit: "contain", // Prevents the image from overflowing
    borderRadius: "8px",
    transition: "transform 0.3s ease",
  },
  zoomedCardImageWrapper: {
    width: "90%",
    height: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%", // Ensures the zoomed image does not go beyond screen
  },
  zoomedCardImage: {
    width: "100%",
    maxWidth: "100%", // Ensures zoomed image fits screen width
    height: "auto",
    objectFit: "contain", // Prevents overflow
    borderRadius: "8px",
    cursor: "zoom-out",
  },
  downloadButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
    fontSize: "30px",
    color: "white",
    cursor: "pointer",
  },
  socialContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  socialLink: {
    textDecoration: "none",
    color: "#000",
    fontSize: "1.5em",
  },
  icon: {
    color: "#333", // Adjust color to match your theme
    fontSize: "1.8em",
  },
  // Add media queries to handle mobile responsiveness
  '@media (max-width: 600px)': {
    cardContainer: {
      padding: "10px",
      maxWidth: "100%", // Ensure full width on mobile
    },
    cardImageWrapper: {
      height: "auto",
    },
    cardImage: {
      maxWidth: "100%", // Ensure image fits within the screen
      objectFit: "contain",
    },
    zoomedCardImageWrapper: {
      width: "100%",
      height: "auto", // Adjust for smaller screens
    },
    zoomedCardImage: {
      maxWidth: "100%", // Ensure zoomed image fits within screen bounds
    },
    downloadButton: {
      padding: "8px 15px", // Slightly smaller buttons for mobile
    },
  },
};

export default DownloadCard;
