import React, { useState, useEffect } from 'react';
import './LaunchAnnouncement.css';

const Confetti = () => {
  const [isLaunched, setIsLaunched] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [currentBanner, setCurrentBanner] = useState(0);

  // Banners to cycle through with highlighted key phrases
  const banners = [
    "🎉 Welcome to <span class='highlight'>ClickThali</span>! Order now and enjoy fresh food at your doorstep! 🍽️",
    // "📦 <span class='highlight'>Free delivery</span> for today only! Don't miss out on this <span class='highlight'>limited-time offer</span>! 🚚💨",
    "🍛 Taste the best of <span class='highlight'>ClickThali</span>! Our <span class='highlight'>delicious meals</span> await you! 🍛😋",
    "🌟 Experience the authentic taste of our <span class='highlight'>delicious thalis</span> today! 🍴<span class='tagline'>आपका स्वाद, हमारी विरासत।</span>🍴"
  ];

  // Timer calculation function
  function calculateTimeLeft() {
    const launchDate = new Date('2024-10-03T11:00:00');
    const now = new Date();
    const difference = launchDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  // Countdown effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());

      if (Object.keys(timeLeft).length === 0) {
        setIsLaunched(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Banner cycling effect
  useEffect(() => {
    if (isLaunched) {
      const bannerInterval = setInterval(() => {
        setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
      }, 3000); // Change banner every 3 seconds

      return () => clearInterval(bannerInterval);
    }
  }, [isLaunched, banners.length]);

  return (
    <div className="launch-container">
      <div className="announcement-board">
        {!isLaunched ? (
          <div className="countdown-clock">
            <p>📅 Mark your calendars! Our services begin on 3rd October. We can't wait to serve you! 🍽️</p>
            <span>Launch in:</span>
            <div className="clock">
              {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
            </div>
          </div>
        ) : (
          <div className="banner-message" dangerouslySetInnerHTML={{ __html: banners[currentBanner] }} />
        )}
      </div>
    </div>
  );
};

export default Confetti;
