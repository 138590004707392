import React from 'react';
import './SponsoredBanner.css'; // Create and link the CSS file for styles

const SponsoredBanner = () => {
  return (
    <div className="sponsored-banner-container">
      <a href="https://sachincsccenter.clickthali.com/" target="_blank" rel="noopener noreferrer">
        <div className="sponsored-banner">
          <div className="sponsored-banner-overlay">
            <button className="sponsored-banner-button">
              Sponsored
            </button>
          </div>
        </div>
      </a>
    </div>
  );
};

export default SponsoredBanner;
