import React, { useState, useEffect } from 'react';

const CountdownClock = ({ startTime, endTime }) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const start = new Date();
      const end = new Date();
      
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);

      start.setHours(startHours, startMinutes, 0, 0);
      end.setHours(endHours, endMinutes, 0, 0);

      if (now < start) {
        // Before the order window opens
        const timeToOpen = start - now;
        const hours = Math.floor((timeToOpen / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeToOpen / (1000 * 60)) % 60);
        const seconds = Math.floor((timeToOpen / 1000) % 60);
        setMessage(`You can order in ${hours}h ${minutes}m ${seconds}s`);
      } else if (now >= start && now <= end) {
        // During the order window
        const timeToClose = end - now;
        const hours = Math.floor((timeToClose / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeToClose / (1000 * 60)) % 60);
        const seconds = Math.floor((timeToClose / 1000) % 60);
        setMessage(`You can order within ${hours}h ${minutes}m ${seconds}s`);
      } else {
        // After the order window closes
        setMessage('Order window closed');
      }
    };

    const timerId = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(timerId); // Clean up timer on unmount
  }, [startTime, endTime]);

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Order Time Information:</h2>
      <div style={styles.clock}>
        {message}
      </div>
    </div>
  );
};

// Define smaller styles for the clock display
const styles = {
  container: {
    backgroundColor: '#f5e6cc', // Light brown background
    padding: '10px', // Reduced padding
    borderRadius: '8px', // Smaller border radius
    textAlign: 'center',
  },
  title: {
    color: '#4b3832', // Dark brown color for text
    fontFamily: "'Arial', sans-serif",
    fontSize: '1rem', // Smaller font size for the title
    marginBottom: '5px', // Reduced margin
  },
  clock: {
    color: '#d35400', // Dark orange color for time digits
    fontSize: '1.2rem', // Smaller font size for the clock
    fontWeight: 'bold',
    fontFamily: "'Courier New', monospace",
  }
};

export default CountdownClock;
